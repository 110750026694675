import React from "react";
import { LogoImage } from "@/data";

const Footer = () => {
  const { light } = LogoImage;
  return (
    <footer className="footer_1">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-sm-6 col-md-5">
            <aside className="widget aboutwidget">
              <a href="/">
                <img src={light} alt="" />
              </a>
              <p>
                Fertile caters to the urban gardener, the homeowner and
                landscape contractor. Our experienced staff offers personalized
                service. Our buyers and sales people are one-in-the-same,
                ensuring product knowledge and the ability to provide
                exceptional advice. Our remodeled facility creates a botanical
                urban oasis for all.
              </p>
            </aside>
          </div>
          <div className="col-lg-4 col-sm-4 col-md-4">
            <aside className="widget contact_widgets">
              <h3 className="widget_title">contact</h3>
              <p>
                3645 N. Kedzie
                <br />
                Chicago, IL 60618
                <br />
                United States of America
              </p>
              <p>P: 773 472 8733</p>
              <p>
                E: <a>fertilegardencenterllc@gmail.com</a>
              </p>
            </aside>
          </div>
          <div className="col-lg-3 col-sm-2 col-md-3">
            <aside className="widget social_widget">
              <h3 className="widget_title">social</h3>
              <ul>
                <li>
                  <a href="https://www.instagram.com/fertilegardencenterllc/?hl=en">
                    <i className="fa fa-instagram"></i>Instagram
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-facebook-square"></i>Facebook
                  </a>
                </li>
              </ul>
            </aside>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
