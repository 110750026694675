import React from "react";

const GoogleMap = ({ extraClass }) => {
  return (
    <div className={`google-map__${extraClass}`}>
      <iframe
        title="template google map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2967.4375499648886!2d-87.70967868425075!3d41.94793596901938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fcd9020c7991f%3A0x4ea45f4635857024!2s3645%20N%20Kedzie%20Ave%2C%20Chicago%2C%20IL%2060618!5e0!3m2!1sen!2sus!4v1635234446698!5m2!1sen!2sus"
        className={`map__${extraClass}`}
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default GoogleMap;
