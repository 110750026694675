import React, { useState, useEffect } from "react";
import { ContactFormTitle } from "@/data";
import validator from "validator";
import emailjs from "emailjs-com";
import axios from "axios";
import ReCaptchaV2 from "react-google-recaptcha";

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    const { subTitle, title, description } = ContactFormTitle;
    this.subTitle = subTitle;
    this.title = title;
    this.description = description;
    this.data = {
      service_id: "service_qolv9if",
      template_id: "template_vdtxs5i",
      user_id: "user_q1KoXGdfpX2N1VWGg6U0J",
      template_params: {},
    };
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
      firstNameError: false,
      lastNameError: false,
      emailError: false,
      phoneError: false,
      messageError: false,
    };
  }
  emailValid = () => {
    if (this.state.email === "") {
      this.setState({ emailError: true });
      return [false, null];
    } else if (validator.isEmail(this.state.email)) {
      this.setState({ emailError: false });
      return [true, this.state.email];
    } else {
      this.setState({ emailError: true });
      return [false, null];
    }
  };

  isValid = inputType => {
    if (this.state[inputType] === "") {
      this.setState({ [`${inputType}Error`]: true });
      return [false, null];
    } else {
      this.setState({ [`${inputType}Error`]: false });
      return [true, validator.escape(this.state[inputType])];
    }
  };

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
    console.log("new state", this.state);
  };
  resetState = () => {
    this.setState({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
      firstNameError: false,
      lastNameError: false,
      emailError: false,
      phoneError: false,
      messageError: false,
    });
  };
  sendEmail = async (firstName, lastName, email, phone, message) => {
    const data = {
      service_id: "service_qolv9if",
      template_id: "template_vdtxs5i",
      user_id: "user_q1KoXGdfpX2N1VWGg6U0J",
      template_params: {
        from_name: `${firstName} ${lastName}`,
        email: email,
        phone: phone,
        message: message,
      },
    };
    console.log("data", data);

    const res = await axios({
      method: "post",
      url: "https://api.emailjs.com/api/v1.0/email/send",
      data: data,
    });
    // console.log(res);
  };

  validate = evt => {
    evt.preventDefault();
    let [isEmailValid, sanitzedEmail] = this.emailValid();
    let [isFirstNameValid, sanitizedFirstName] = this.isValid("firstName");
    let [isLastNameValid, sanitizedLastName] = this.isValid("lastName");
    let [isPhoneValid, sanitizedPhone] = this.isValid("phone");
    let [isMessageValid, sanitizedMessage] = this.isValid("message");

    if (
      isEmailValid &&
      isFirstNameValid &&
      isLastNameValid &&
      isPhoneValid &&
      isMessageValid
    ) {
      console.log("the message would be sent");
      // this.sendEmail(
      //   sanitizedFirstName,
      //   sanitizedLastName,
      //   sanitzedEmail,
      //   sanitizedPhone,
      //   sanitizedMessage
      // );
      this.resetState();
    }
  };
  render() {
    return (
      <section className="commonSection ContactPage">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h4 className="sub_title">{this.subTitle}</h4>
              <h2 className="sec_title">{this.title}</h2>
              <p className="sec_desc">{this.description}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 offset-lg-2 col-sm-12 col-md-10 offset-md-1">
              {this.state.firstNameError ? (
                <p style={{ color: "red" }}>Please Enter a First Name</p>
              ) : null}
              {this.state.lastNameError ? (
                <p style={{ color: "red" }}>Please Enter a Last Name</p>
              ) : null}
              {this.state.emailError ? (
                <p style={{ color: "red" }}>Please Enter a Valid Email</p>
              ) : null}
              {this.state.phoneError ? (
                <p style={{ color: "red" }}>Please Enter a Phone Number</p>
              ) : null}
              {this.state.messageError ? (
                <p style={{ color: "red" }}>Please Enter a Message</p>
              ) : null}

              <form
                action="#"
                method="post"
                className="contactFrom"
                id="contactForm"
                onSubmit={this.validate}
              >
                <div className="row">
                  <div className="col-lg-6 col-sm-6">
                    <input
                      className="input-form required"
                      type="text"
                      name="firstName"
                      value={this.state.firstName}
                      onChange={this.handleChange}
                      id="f_name"
                      placeholder="First Name"
                    />
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    <input
                      className="input-form required"
                      type="text"
                      name="lastName"
                      value={this.state.lastName}
                      onChange={this.handleChange}
                      id="l_name"
                      placeholder="Last Name"
                    />
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    <input
                      className="input-form required"
                      // type="email"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      // id="email"
                      placeholder="Email Address"
                    />
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    <input
                      className="input-form"
                      type="text"
                      name="phone"
                      value={this.state.phone}
                      onChange={this.handleChange}
                      id="phone"
                      placeholder="Phone Number"
                    />
                  </div>
                  <div className="col-lg-12 col-sm-12">
                    <textarea
                      className="input-form required"
                      name="message"
                      value={this.state.message}
                      onChange={this.handleChange}
                      id="con_message"
                      placeholder="Write Message"
                    ></textarea>
                  </div>
                </div>

                <button
                  className="common_btn red_bg"
                  type="submit"
                  id="con_submit"
                >
                  <span>Send Message</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ContactForm;
